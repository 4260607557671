$primary-color: #005FAA;
$secondary-color: #009B3E;
$top-header: #E52713;
$top-header-search:#d4d4d4;
$card-hover: rgba(61, 151, 202, 0.4);
$address-button: #e7f7ff;
$bottom-footer: #024880;

$call-to-action: #009B3E;




