@import "../../../../scss/variables";

.header {
    height: 115px;
    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
        background-color: #fafafa;
        max-width: 2000px;
        margin: 0 auto;
    }

    &-top {
        padding-left: 56px;
        padding-right: 40px;
        height: 30px;
        background-color: $top-header;
        color: #ffffff;

        span {
            margin-top: 5px;
            margin-bottom: 7px;
            font-family: FuturaStd-Bold;
            font-size: 12px;
            letter-spacing: 0.1px;
            text-transform: uppercase;
            &.status {
                text-transform: lowercase;
                font-family: FuturaStd-Book;
            }
        }

        .order {
            flex-grow: 1;
            text-align: center;
            padding-top: 4px;
            padding-bottom: 8px;
            font-family: FuturaStd-Book;
            font-size: 12px;
            letter-spacing: 0.1px;
            &-link {
                color: unset;
                text-decoration: none;
            }
        }
        .pin {
            width: 14px;
            height: 18.4px;
            object-fit: contain;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 6.6px;
            &-link {
                color: unset;
                text-decoration: none;
            }
        }
        .shop {
            width: 17px;
            height: 16.8px;
            object-fit: contain;
            margin-right: 11px;
            margin-top: 7px;
            margin-bottom: 6.2px;
            &-link {
                color: unset;
                text-decoration: none;
            }
        }
    }

    &-bottom {
        padding-left: 56px;
        padding-right: 10px;
        height: 85px;
        // background-color: #ffffff;
        background-color: $primary-color;
        border-bottom: 2px solid $primary-color;

        .search {
            display: flex;
            margin-top: 23px;
            margin-bottom: 22px;
            form {
                display: flex;
                width: 100%;
                margin-top: unset;
            }
            &-icon {
                width: 18px;
                height: 18px;
            }
            input {
                height: 40px;
                width: 100%;
                border: 1px solid $top-header-search;
                border-right: none;
                padding-left: 11px;
                padding-right: 5px;
                &::placeholder {
                    letter-spacing: 0.12px;
                    font-size: 14px;
                    color: $top-header-search;
                }
            }
            button {
                width: 40px;
                height: 40px;
                background-color: $call-to-action;              
                border: none;
            }
        }
    }
}

