@import '../../scss/_variables.scss';

.profile-menu {
    background-color: #f7f7f7;
    padding-top: 60px;
    padding-left: 60px;
    @media screen and (max-width: "768px") {
        padding-left: 30px;
    }
    @media screen and (max-width: "576px") {
        padding-left: 30px;
    }
   
    .info {
        font-family: FuturaStd-Book;
        font-size: 14px;
        letter-spacing: 0.39px;
        color: #212528;
        text-transform: uppercase;
        padding-bottom: 13px;
    }
    .name {
        font-family: Futura-Medium;
        font-size: 30px;
        font-weight: 500;
        color: #212528;
        padding-bottom: 34px;
    }
    .profile-link {
        font-family: FuturaStd-Bold;
        font-size: 16px;
        color: #0075b5; 
        text-transform: uppercase;
        padding-bottom: 20px;
        &:hover {
            a {
                color: $primary-color;
            }
        }
        &-active {
            a {
                color: $primary-color;
            }
        }
    }
}