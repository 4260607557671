@import "../../../scss/variables";

.banner-comune-modal-popup {
  width: 721px;
  background-color: #ffffff;
  align-items: center;
  padding-bottom: 20px;
  margin: auto;
  @media screen and (max-width: "576px") {
    width: unset;
  }

  .close {
    width: 16px;
    height: 16px;
    padding-right: 25px;
    cursor: pointer;
    color: #212528;
    opacity: unset;
    @media screen and (max-width: "576px") {
      width: unset;
      height: 0;
      float: right;
      padding-right: 5px;

    }
  }

  hr {
    height: 1px;
    color: #d2d2d2;
    max-width: 673px;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  .banner-vivo-image {
    width: 130px;
  }

  &-header {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: "576px") {
      padding-right: 20px;
      padding-left: 20px;
    }

    .banner-comune-logo {
      width: 103px;
      height: 68px;
      margin-bottom: 27px;
      align-content: center;

    }

    .sub-comune-banner-header-title {
      width: 601px;
      height: 58px;
      font-family: Futura;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.31px;
      text-align: center;
      color: #333333;

      @media screen and (max-width: "576px") {
        font-size: 18px;
        width: unset;
        height: unset;
      }
    }
  }

  .comune-input-div {
    justify-content: center;
    @media screen and (max-width: "576px") {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .comune-input {
      width: 525px;
      height: 40px;
      border: solid 1px #979797;
      padding-left: 16px;
      background-color: white;
      margin-right: 20px;
      @media screen and (max-width: "576px") {
        width: 100%;
        margin-right: unset;
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .popup-content {
    max-width: 633px;
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media screen and (max-width: "576px") {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &-actions {
    padding-bottom: 34px;
    @media screen and (max-width: "576px") {
      padding-bottom: 10px;
    }

    button {
      width: 180px;
      cursor: pointer;
    }

    .verifica-comune {
      width: 113px;
      height: 40px;
      border: none;
      background-color: #006633;
      @media screen and (max-width: "576px") {
        width: 90px;
      }

      .text-verifica {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.9px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }
}

.banner-comune-modal-popup-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.banner-spiacenti {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  @media screen and (max-width: "576px") {
    padding-top: unset;
  }
}


.banner-siamo-spiacenti {
  text-align: center;
}


.errorLogo-icon {
  border-radius: 15px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.21);
  background-color: #ffffff;
}


.delivery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: "576px") {
    flex-direction: column;
    margin-bottom: 2px;
    padding: 10px;
  }
}

.delivery-text {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: "576px") {
    flex-direction: column;
    padding: 10px;
  }
}

.delivery-home {
  width: 228px;
  height: 103px;
  background-color: #005faa;
  margin-left: 20px;
  margin-right: 20px;

  @media screen and (max-width: "576px") {
    margin-bottom: 10px;
  }


  .delivery-container {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: "576px") {
      flex-direction: column;
    }

    .delivery-logo {
      height: 33px;
      object-fit: contain;
      margin-bottom: 5px;
      margin-right: auto;
      margin-left: auto;
    }

    .link-delivery {
      //width: 179px;
      height: 17px;
      font-family: Futura;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: 0.44px;
      text-align: center;
      color: #ffffff;

    }
  }
}





