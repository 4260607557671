@import "../../../../scss/variables";

.mobile-header {
    // height: 133px;
    height: 85px;
    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
    }

    &-top {
        padding-left: 16px;
        padding-right: 16px;
        height: 30px;
        background-color: $top-header;
        color: #ffffff;

        span {
            margin-top: 7px;
            margin-bottom: 7px;
            font-family: FuturaStd-Bold;
            font-size: 10px;
            @media screen and (max-width: "576px") {
                font-size: 8px;
            }
            letter-spacing: 0.39px;
            text-transform: uppercase;
        }
        .pin {
            width: 11px;
            height: 14px;
            object-fit: contain;
            margin-right: 10px;
            margin-top: 8px;
            margin-bottom: 8px;
            &-link {
                color: unset;
                text-decoration: none;
            }
        }
        .shop {
            width: 11px;
            height: 11px;
            object-fit: contain;
            margin-right: 10px;
            margin-top: 10px;
            margin-bottom: 9px;
            &-link {
                color: unset;
                text-decoration: none;
            }
        }
        .shop-link {
            span {
                letter-spacing: 0.87px;
            }
        }
    }

    &-bottom {
        padding-left: 16px;
        padding-right: 16px;
        height: 55px;
        background-color: $primary-color;
        border-bottom: 1px solid #edeaea;

        .logo {
            width: 145px;
            height: 29px;
            margin-top: 13px;
            margin-bottom: 13px;
            img {
                max-width: 100%;
            }
            svg {
                width: 40%;
            }
        }
    }

    &-search {
        padding-left: 16px;
        padding-right: 16px;
        height: 48px;
        background-color: $primary-color;
        display: flex;
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 1px solid $primary-color;
        &-icon {
            width: 18px;
            height: 18px;
        }

        form {
            display: flex;
            width: 100%;
            margin-top: unset;
        }

        input {
            border-radius: 0;
            -webkit-appearance: none;
            height: 30px;
            width: 100%;
            border: 1px solid $top-header-search;
            border-right: none;
            padding-left: 8px;
            padding-right: 5px;
            &::placeholder {
                letter-spacing: 0.87px;
                font-size: 10px;
                color: $top-header-search;
            }
        }
        button {
            width: 70px;
            height: 30px;
            background-color: $call-to-action;              
            border: none;
            span {
                color: #ffffff;
                letter-spacing: 0.87px;
                font-size: 10px;
                font-family: FuturaStd-Bold;
                text-transform: uppercase;
            }
        }
    }
}