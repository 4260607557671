@import '../../../scss/_variables.scss';

.card-product {
    width: 100%;
    max-width: 225px;

    // margin: 0 20px 50px;
    // height: 380px;
    margin: 0 10px 30px;
    height: 350px;

    @media screen and (max-width: 350px) {
        max-width: 135px;
        height: 300px;
        margin: 0 5px 20px;
    }
    @media screen and (min-width: 351px) and (max-width: 390px) {
        max-width: 150px;
        height: 300px;
        margin: 0 5px 20px;
    }
    @media screen and (min-width: 390px) and (max-width: 539px) {
        max-width: 170px;
        height: 300px;
        margin: 0 5px 20px;
    }
    @media screen and (min-width: 540px) and (max-width: 576px) {
        max-width: 155px;
        height: 300px;
        margin: 0 5px 20px;
    }
    
    background-color: #ffffff;
    &:hover {
        @media screen and (min-width: 992px) {
            box-shadow: 0 10px 21px 0 $card-hover;
        }
    }

    &-container {
        position: relative;
        width: 100%;
        height: 202px;
        @media screen and (max-width: "576px") {
            height: 175px;
        }
    }

    &-image {
        text-align: center;
        line-height: 120px;
        overflow: hidden;
        line-height: 202px;
        @media screen and (max-width: "576px") {
            line-height: 175px;
        }
        
        img {

            @media screen and (max-width: "576px") {
                // max-height: 100px;
                // max-width: 100px;
                max-height: 150px;
                max-width: 130%;
                padding-bottom: unset;
            }            
            
            padding-bottom:3px;
            margin-top:3px;
            max-height: 200px;
            max-width: 130%; 
            margin-left: 20px;
            margin-right: 20px;
            // max-height: 122px;
            // max-width: 122px;
            z-index: 1;
        }
    }

    &-promo {
        position: absolute;
        z-index: 2;
        height: 26px;
        top: 15px;
        box-shadow: 1px 1px 2px 0 #bbbbbb;
        background-color: red;
        text-transform: uppercase;
        span {
            padding-left: 12px;
            padding-right: 8px;
            font-family: FuturaStd-Bold;
            font-size: 10px;
            line-height: 2.2;
            letter-spacing: 0.4px;
            color: #ffffff;
        }
    }

    &-details {
        // margin-top: 19px;
        margin-top: 3px;
        height: 35px;
        padding-right: 10px;
        padding-left: 10px;
        @media screen and (max-width: "576px") {
            height: 35px;
        }

        span {
            font-size: 12px;
            height: 35px;
            @media screen and (max-width: "576px") {
                font-size: 11px;
                height: 30px;
            }

            font-family: FuturaStd-Book;
            // font-size: 14px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.6px;
            color: #212529;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &-um {
        margin-top: 4px;
        margin-bottom:4px;
        padding-left: 10px;
        padding-right: 10px;
        @media screen and (max-width: 576px) {
            padding-right: 10px;
        }
        padding-bottom: 5px;
        font-size: 9px;
        height: 20px;
        line-height: 30px;
        letter-spacing: 0.87px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icons {
            float: right;
            font-family: FuturaStd-Bold;
        }
        svg {
            width: 30px;
            @media screen and (max-width: 576px) {
                width: 25px;
            }
        }
    }
    &-prices {
        padding-left: 10px;
        padding-right: 10px;
        // margin-top: 4px;
        display: flex;
        justify-content: space-between;
        .strike {
            font-size: 12px;
            letter-spacing: 1.05px;
            // color: $secondary-color;
            color: #7a7a7a;
        }
        .netTotal {
            font-size: 16px;
            @media screen and (max-width: "576px") {
                font-size: 12px;
            }
            font-family: FuturaStd-Bold;
            text-align: right;
            // color: $secondary-color;
            color: #092853;
        }
    }
    &-buttons {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 2px;
        padding-bottom: 12px;
        button {
            border-radius: 8px;
            border: solid 1px $secondary-color;
            background-color: #ffffff;
            svg {
                path {
                    fill: $secondary-color;
                }
            }
            &:hover {
                background-color: $secondary-color;
                color: #ffffff;
                svg {
                    path {
                        fill: #ffffff;
                    }
                }
                span {
                    color: #ffffff;
                }
            }
        }
        .slist {
            width: 45px;
            height: 47px;
            svg {
                width: 100%;
                height: 23px;
                margin-bottom: 3px;
            }
            @media screen and (max-width: "576px") {
                width: 60px;
                height: 25px;
                svg {
                    height: 20px;
                    margin-bottom: 5px;
                }
            }
        }
        .cart {
            margin-left: 15px;
            width: 150px;
            height: 47px;
            @media screen and (max-width: "576px") {
                width: 60px;
                height: 25px;
            }
            span {
                padding-left: 10px;
                font-family: Futura-Medium;
                font-size: 15px;
                color: $secondary-color;
            }
        }
    }
    &-unavailable {
        margin-top: 0px;
        padding-left: 6px;
        padding-right: 6px;
        @media screen and (max-width: "576px") {
            padding-left: 6px;
            padding-right: 6px;
            font-size: 9px;
        }
        .unavailable-content {
            border-radius: 8px;
            background-color: #f2f2f2;
            text-align: center;
            padding: 5px;
            line-height: 1.2;
            span {
                font-size: 12px;
                @media screen and (max-width: "576px") {
                    font-size: 9px;
                }
                color: #d81818;
                text-transform: uppercase;
                font-family: FuturaStd-Book;
                letter-spacing: 1.5px;
            }
        }
    }
    &-icons {
        padding-left: 13px;
        width: 30px;
        height: 30px;
    }
}