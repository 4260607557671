@import "../../../../scss/variables";

.address-popup {
    max-width: 721px;
    @media screen and (max-width: "576px") {
        width: unset;
    }
    border-radius: 15px;
    background-color: #ffffff;
    .close {
        width: 16px;
        height: 16px;
        padding-right: 20px;
        cursor: pointer;
        color: #212528;
        opacity: unset;
    }
    &-form {
        padding-left: 45px;
        padding-right: 45px;
        @media screen and (max-width: "576px") {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &-header {
        text-align: center;
        font-family: FuturaStd-Bold;
        font-size: 16px;
        color: #212528;
        text-transform: uppercase;
        padding-top: 34px;
        padding-bottom: 34px;
    }
    &-content {
        .popup-row {
            padding-bottom: 30px;
        }
    }
    &-actions {
        padding-top: 5px;
        height: 70px;
        button {
            width: 200px;
            @media screen and (max-width: "576px") {
                width: unset;
            }
        }
        .delete {
            height: 44px;
            border-radius: 8px;
            border: solid 1px #dd0202;
            span {
                font-family: Futura;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.9px;
                color: #dd0202;
                text-transform: uppercase;
            }
        }
        .save {
            float: right;
            height: 44px;
            border-radius: 8px;
            border: solid 1px $primary-color;
            background-color: $primary-color;
            span {
                font-family: Futura;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.9px;
                color: #ffffff;
                text-transform: uppercase;
            }
        }
    }
}