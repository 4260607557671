@import "../../../scss/variables";

.smartlist-header {
  background-color: #f7f7f7;

  .content {
    display: flex;
    justify-content: center;
  }

  select {
    height: 35px;
    width: 250px;
    margin-right: 20px;
    border: 2px solid $primary-color !important;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.smartlist-header-button {
  height: 35px;
  margin-right: 20px;
  border: none;
  background-color: $primary-color;
  color: white;
  font-family: FuturaStd-Bold;
  text-transform: uppercase;
  font-size: 12px;

  &:hover {
    transition: ease-in-out;
    transition-duration: 0.2s;
    border: 2px solid $primary-color !important;
    background-color: transparent;
    color: $primary-color;
  }

  &:disabled {
    background-color: #807a7a;
    pointer-events: none;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }

  span {
    padding: 0 15px;
    letter-spacing: 0.6px;
  }

  &--secondary {
    border: 2px solid $primary-color !important;
    background-color: transparent;
    color: $primary-color;

    .text {
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      transition: ease-in-out;
      transition-duration: 0.2s;
      border: none;
      background-color: $primary-color;
      color: white;
    }
  }
}