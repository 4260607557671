@import "../../../scss/variables";

.stripe-button {
    width: 204px;
    height: 44px;
    border-radius: 8px;
    border: solid 1px $primary-color;
    background-color: $primary-color;
    span {
        font-family: FuturaStd-Book;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.9px;
        color: #ffffff;
    }
}